import {tap} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({providedIn: 'root'})
export class SendSmsService {

    /**
     * Notification to any subscribers of note save events
     * @type {EventEmitter<boolean>}
     */
    @Output() onSmsSend = new EventEmitter<any>();


    constructor(private http: HttpClient) {
    }



    sendSms(sendSmsRequest) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.post('/api/sms/send', sendSmsRequest, {headers}).pipe(tap(
            (data) => {
                this.notify(data);
            }));
    }

    /**
     * Notify any subscribers
     * @param event
     */
    notify(event) {
        this.onSmsSend.emit(event);
    }
}
