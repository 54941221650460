import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorService} from 'src/app/shared/error/error.service';
import {CustomerLite} from '../../models/customer-lite.model';
import {SendEmailRequest} from '../../models/send-email-request.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {SendEmailService} from '../services/send-email.service';
import {AlertService} from '@ratespecial/core';
import { ErrorComponent } from '../../../shared/error/error.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-send-email-modal',
    templateUrl: './send-email-modal.component.html',
    styleUrls: ['./send-email-modal.component.scss'],
    standalone: true,
    imports: [ErrorComponent, FormsModule]
})
export class SendEmailModalComponent {

  /**
   * Customer Data needed for request
   */
  public customer: CustomerLite;

  public sendEmailRequest: SendEmailRequest = {
    guid: '',
    type: '',
  };

  constructor(private alertService: AlertService,
              private sendEmailService: SendEmailService,
              public activeModal: NgbActiveModal,
              public errorService: ErrorService
  ) { }

  public sendEmail() {
    this.errorService.clearErrors();
    this.sendEmailRequest.guid = this.customer.guid;

    this.sendEmailService.send(this.sendEmailRequest)
      .subscribe(
        (data) => {
          this.alertService.success('Email sent!');
          this.close();
        },
        (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        }
      );
  }

  close() {
    this.activeModal.close();
  }
}
