import {Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AccountSettings} from "../../shared/models/account-settings.interface";
import { DateTimeFormatPipe } from '../../shared/pipes/date-time-format.pipe';

@Component({
    selector: 'app-smart-offers-info-modal',
    templateUrl: './smart-offers-info-modal.component.html',
    styleUrls: ['./smart-offers-info-modal.component.scss'],
    standalone: true,
    imports: [DateTimeFormatPipe]
})
export class SmartOffersInfoModalComponent {

  accountSettings: AccountSettings = null;

  constructor(public activeModal: NgbActiveModal) { }

}
