import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';


@Injectable({providedIn: 'root'})
export class OfferClickService {

    private resourceUrl = 'api/logs/offerclicks';

    constructor(private http: HttpClient) {
    }

    getByGuid(guid, page) {

        let params = new HttpParams();
        params = params.append('page', page);

        return this.http.get<LengthAwarePaginator>(this.resourceUrl + '/' + guid, {params: params});
    }
}
