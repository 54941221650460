<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Password Reset</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  @if (customerExpired()) {
  <div class="alert alert-warning" role="alert">
    Customer is cancelled and past their final date of access.  Password reset will fail.
  </div>
  }

  <p>Are you sure you want to send password reset notification to <strong>{{customer?.fname}} {{customer?.lname}}</strong>?</p>

  @if (provider === 'EMAIL') {
    <p>A notification will be sent to the email address currently associated with the account: <strong>{{customer?.email}}</strong></p>
  } @else {
    <p>A notification will be sent to the phone number currently associated with the account: <strong>{{customer?.phone | ukPhone}}</strong></p>
  }


  <div class="mt-5 mb-4">
    <p class="provider-item">
      <span class="fw-bold">Delivery method</span> <br>
      <span class="small">Go with the default unless they don't receive the message in a timely fashion.</span>
    </p>

    <div class="provider-radios provider-item">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="provider" value="EMAIL" id="email" [(ngModel)]="provider">
        <label class="form-check-label" for="email">
          Email
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="provider" value="SMS" id="sms" [(ngModel)]="provider">
        <label class="form-check-label" for="sms">
          SMS
        </label>
      </div>
    </div>
  </div>

  @if (reset_url) {
  <p *appUserAbility="'reset-password-url'">
    <strong style="color:#0088CB">RESET URL</strong>
    <br>
    <textarea style="width:100%; word-wrap:break-word;min-height: 7rem;">{{reset_url}}</textarea>
  </p>
  }

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="send()">Send</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
</div>




