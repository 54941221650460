import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Pricepoint} from 'src/app/customer/pricepoint/model/pricepoint';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import * as SessionActions from 'src/app/shared/state/session.actions';
import {tap} from 'rxjs/operators';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class PricepointService {

  constructor(private http: HttpClient, private store: Store) {
  }

  get() {
    // If we have an empty state, fetch from the server
    if (this.store.selectSnapshot(SessionState.getDownsellPricepoints).length == 0) {
      this.http
        .get<Pricepoint[]>('api/pricepoint')
        .pipe(
          tap(prices => this.store.dispatch(new SessionActions.UpdateDownsellPricepoints(prices))),
        )
        .subscribe({
          next: () => console.debug('Updated downsell pricepoints'),
          error: e => console.error(e),
        });
    }

    // Always return from the state.
    return this.store.select(SessionState.getDownsellPricepoints);
  }

  /**
   * Make an account free
   * This now uses the same endpoint as updateAccountPricing
   * @param guid Customer GUID
   * @param bsid Billing system ID (optional)
   * @returns Observable of the API response
   */
  makeAccountFree(guid: string, bsid?: number): Observable<any> {
    return this.updateAccountPricing(guid, bsid || 19, '0.00');
  }

  /**
   * Update account pricing
   * @param guid Customer GUID
   * @param bsid Billing system ID
   * @param recurringPrice New recurring price
   * @returns Observable of the API response
   */
  updateAccountPricing(guid: string, bsid: number, recurringPrice: string): Observable<any> {
    return this.http.put(`/api/customer/${guid}/pricepoint`, {
      guid,
      bsid,
      recurring_price: recurringPrice
    });
  }
}
