import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../../shared/models/LengthAwarePaginator';
import { NgFor, NgSwitch, NgSwitchCase, NgIf } from '@angular/common';
import { NgbTooltip, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeFormatPipe } from '../../../shared/pipes/date-time-format.pipe';

@Component({
    selector: 'app-customer-cancel-events',
    templateUrl: 'customer-cancel-events.component.html',
    styleUrls: ['customer-cancel-events.component.css'],
    standalone: true,
    imports: [
        NgFor,
        NgSwitch,
        NgSwitchCase,
        NgbTooltip,
        NgIf,
        NgbPagination,
        DateTimeFormatPipe,
    ],
})
export class CustomerCancelEventsComponent implements OnInit {

  private guid = '';
  public cancelevents: LengthAwarePaginator;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.guid = params['guid'];
        this.fetchCancelEvents(1);
      },
    );
  }

  onPageChange(page) {
    this.fetchCancelEvents(page);
  }

  fetchCancelEvents(page) {

    let params = new HttpParams();
    params = params.append('page', page);

    this.http.get<LengthAwarePaginator>('api/logs/cancelevents/' + this.guid, {params: params})
      .subscribe(lengthAwarePaginator => {
        this.cancelevents = lengthAwarePaginator;
      });
  }

}
