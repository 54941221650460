import {Observable, switchMap} from 'rxjs';
import {finalize, map, share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Customer} from '../models/customer.model';
import {AccountSettings} from "../../shared/models/account-settings.interface";
import {Store} from '@ngxs/store';
import {CustomerState} from 'src/app/shared/state/customer.state';
import * as CustomerActions from 'src/app/shared/state/customer.actions';
import {Screenshot} from 'src/app/customer/models/screenshot';
import {ExtendTrialResponse} from 'src/app/customer/models/extend-trial-response';
import {UpdatePricepointRequest} from 'src/app/customer/pricepoint/model/update-pricepoint-request';
import {RefundService} from 'src/app/customer/transactions/refund.service';
import {RentInfoResponse} from 'src/app/rent-reporting/models/rent-declaration-info';
import {DataPartnerResponse} from 'src/app/customer/models/data-partner';
import {BudTransaction} from 'src/app/rent-reporting/models/bud-transaction';
import {CustomerSearchResponse} from 'src/app/customer/models/customer-search-response';

@Injectable({providedIn: 'root'})
export class CustomerService {

  // GUID fetch() is running with
  private fetchGuid = '';
  // Currently executing fetch() call
  private fetchObs: Observable<Customer> | null = null;

  constructor(private http: HttpClient, private store: Store, private refundService: RefundService) {
    // I don't know why we would need to update the customer if a refund occurs.  It was being done in the customer-detail page, so let's
    // keep doing it until we decide it's not necessary.
    this.refundService.refunded.subscribe(req => this.fetchCustomerIfInStateAndComplete(req.guid));
  }

  /**
   * Fetch customer entity from the server
   */
  fetch(guid: string): Observable<Customer> {
    // Prevent duplicate fetches on the same guid at the same time.  Allow fetches for different GUIDs
    if (!this.fetchObs || this.fetchGuid !== guid) {
      this.fetchGuid = guid;

      this.fetchObs = this.http
        .get<Customer>(`/api/customer/${guid}`)
        .pipe(
          share(),
          map(resp => new Customer(resp)),
          finalize(() => {
            this.fetchObs = null;
            this.fetchGuid = '';
          }),
        );
    }

    return this.fetchObs;
  }


  /**
   * Update Customer Record.  Should be a state action.
   */
  update(guid: string, params){
    return this.http
      .put(`/api/customer/${guid}`, params)
      .pipe(
        // Auto pull a new customer and update the state
        switchMap(() => this.store.dispatch(new CustomerActions.UpdateCustomer(guid, true))),
      );
  }


  search(searchCriteria) {
    return this.http.post<{customers: CustomerSearchResponse[]}>('/api/customer/search', searchCriteria);
  }


  extendTrial(guid: string): Observable<ExtendTrialResponse> {
    return this.http
      .get<ExtendTrialResponse>(`/api/customer/${guid}/extend-trial`)
      .pipe(
        // Auto pull a new customer and update the state
        tap(() => this.store.dispatch(new CustomerActions.UpdateCustomer(guid, true))),
      );
  }

  getScreenshotDetail(guid: string, trans_num: number): Screenshot | null {
    const cust = this.store.selectSnapshot(CustomerState.getCustomer);

    if (!cust || cust.guid !== guid) {
      throw Error('GUID in state does not match request');
    }

    if (cust.screenshots) {
      for (const ss of cust.screenshots) {
        if (ss.trans_num == trans_num) {
          return ss;
        }
      }
    }

    return null;
  }

  delete(guid: string) {
    return this.http.delete(`/api/customer/${guid}`);
  }

  canPartialRefund(processorId: number): boolean {
    return !(processorId == 9 ||
      processorId == 10 ||
      processorId == 12 ||
      processorId == 13);
  }

  convertAgencyGuidToOurGuid(agencyGuid: string): Observable<string> {
    return this.http
      .get('/api/customer/agency-guid-to-guid', {params: {guid: agencyGuid}})
      .pipe(
        map(r => r['ourGuid']),
      );
  }

  refreshReport(guid: string): Observable<any> {
    return this.http.post(`/api/customer/${guid}/refresh-report`, {});
  }

  getUserSettings(uid: number): Observable<AccountSettings> {
    return this.http.get<AccountSettings>('/api/customer/' + uid + '/user-settings');
  }

  sendOneTimePassword(guid: string, provider: string = 'TW'): Observable<any> {
    return this.http.post(`/api/customer/${guid}/send-one-time-password`, {provider});
  }

  updatePricepoint(pricepoint: UpdatePricepointRequest) {
    return this.http
      .put(`api/customer/${pricepoint.guid}/pricepoint`, pricepoint)
      .pipe(
        tap(() => this.fetchCustomerIfInStateAndComplete(pricepoint.guid)),
      );
  }

  queryAccountStatus(guid: string): Observable<boolean> {
    return this.http
      .get(`/api/customer/${guid}/account-status`)
      .pipe(
        map(r => r['result']),
      );
  }

  closeUser(guid: string): Observable<boolean> {
    return this.http
      .post(`/api/customer/${guid}/close-user`, {})
      .pipe(
        map(r => r['status']),
      );
  }

  getPartnerList(): Observable<DataPartnerResponse> {
    return this.http.get<DataPartnerResponse>(`/api/customer/partner-list`);
  }

  updateManualVerifyStatus(guid: string): Observable<any> {
    return this.http.post(`/api/customer/${guid}/manual-verify-complete`, {});
  }


  fetchRentInfo(uid: number): Observable<RentInfoResponse> {
    return this.http.get<RentInfoResponse>(`/api/customer/${uid}/rent-reporting`);
  }

  fetchBankTransactions(uid: number, page: number): Observable<BudTransaction> {
    return this.http.get<BudTransaction>(`/api/customer/${uid}/rent-reporting/bank-transactions`, {params: {page: page}});
  }

  /**
   * Marks bank transactions as rent payments
   */
  flagRentTransactions(uid: number, transactionIds: number[]) {
    return this.http.post(`/api/customer/${uid}/rent-reporting/flag-transactions`, {ids: transactionIds});
  }

  approveRentDeclaration(guid: string, rentInfoId: number) {
    return this.http.post(`/api/customer/${guid}/rent-reporting/approve-declarations`, {rent_info_id: rentInfoId});
  }

  /**
   * Update the customer in the state.  Useful for after operations update operations when we want a fresh copy.
   */
  fetchCustomerIfInStateAndComplete(guid: string): void {
    const currentCustomer = this.store.selectSnapshot(CustomerState.getCustomer);

    if (guid === currentCustomer?.guid) {
      this.fetch(guid).subscribe({
        next: () => console.debug('Fetched customer record'),
        error: () => console.error('Failed to fetch customer record'),
      })
    }
  }

  /**
   * Switch the state to this GUID, UNLESS he's there already.  Useful for going back to a page after an operation (switching between a
   * customer's detail and rent reporting pages).
   */
  // fetchCustomerIfNotInState(guid: string): Observable<Customer> {
  //   const currentCustomer = this.store.selectSnapshot(CustomerState.getCustomer);
  //
  //   if (guid !== currentCustomer?.guid) {
  //     return this.fetch(guid);
  //   } else {
  //     return this.store.selectOnce(CustomerState.getCustomer);
  //   }
  // }
  //
  // /**
  //  * Switch the state to this GUID, UNLESS he's there already.  Subscribe to the observable internally.
  //  */
  // fetchCustomerIfNotInStateAndComplete(guid: string): void {
  //   const currentCustomer = this.store.selectSnapshot(CustomerState.getCustomer);
  //
  //   if (guid !== currentCustomer?.guid) {
  //     this.fetch(guid).subscribe({
  //       next: () => console.debug('Fetched customer record'),
  //       error: () => console.error('Failed to fetch customer record'),
  //     })
  //   }
  // }
}
