<div class="container">
    <h3>Customer Search</h3>

    <div class="card card-body rs-card rs-card-blue">

        <form class="customer-search-form">

            <div class="row">

                <div class="col-md-5">
                    <!--fname -->
                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="fname">First Name</label>
                        <div class="col-md-8">
                            <input type="text" name="fname" id="fname" class="form-control form-control-sm" [(ngModel)]="searchModel.fname" >
                        </div>
                    </div>

                    <!--lname -->
                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="lname">Last Name</label>
                        <div class="controls col-md-8">
                            <input type="text" id="lname" name="lname" class="form-control form-control-sm" [(ngModel)]="searchModel.lname">
                            <span id="errId2" class="error"></span>
                        </div>
                    </div>

                    <!--email -->
                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="email">Email</label>
                        <div class="controls col-md-8">
                            <input type="text" id="email" name="email" class="form-control form-control-sm" [(ngModel)]="searchModel.email">
                            <span id="errId3" class="error"></span>
                        </div>
                    </div>

                    <!-- post code -->
                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="postcode">Post Code</label>
                        <div class="controls col-md-8">
                            <input type="text" id="postcode" name="postcode" class="form-control form-control-sm" [(ngModel)]="searchModel.post_code">
                            <span id="errId4" class="error"></span>
                        </div>
                    </div>

                    <!-- product_id -->
                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="postcode">Product</label>
                        <div class="controls col-md-8">
                            <select name="product" [(ngModel)]="searchModel.product_id" class="form-control form-control-sm">
                                <option *ngFor="let product of products" [value]="product.id">
                                    {{product.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                  <!-- AFID -->
                  <div class="form-group row">
                    <label class="control-label control-label-left col-md-4" for="afid">AFID</label>
                    <div class="controls col-md-8">
                      <input class="form-control form-control-sm" name="afid" id="afid" [(ngModel)]="searchModel.afid">
                    </div>
                  </div>

                  <!-- Deferred auth -->
                  <div class="form-group row">
                    <label class="control-label control-label-left col-md-4" for="deferredAuth">
                      Deferred auth
                      <i class="fa-regular fa-circle-question d-inline-block"  ngbTooltip="Deferred authentication is when processing a customer's credit card has been deferred to after account creation.  They will have to give their card details from in the portal, or we have to contact them and take their details over the phone." container="body"></i>
                    </label>

                    <div class="controls col-md-8">
                      <select name="deferredAuth" [(ngModel)]="searchModel.deferredAuth" id="deferredAuth" class="form-control form-control-sm">
                        <option></option>
                        <option value="yes">Yes</option>
                        <option value="yes-incomplete">Yes, incomplete</option>
                        <option value="yes-complete">Yes, complete</option>
                      </select>
                    </div>
                  </div>

                </div>


                <div class="col-md-7">

                    <!-- post code -->
                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="creditcard">Last 4 of Credit Card</label>
                        <div class="controls col-md-8">
                            <input type="text" name="creditcard" id="creditcard" class="form-control form-control-sm" [(ngModel)]="searchModel.last_four">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="dp1">Date Range</label>
                        <div class="controls col-md-4">
                            <div class="input-group">
                                <input class="form-control form-control-sm" placeholder="yyyy-mm-dd" name="dp1" id="dp1"
                                       [(ngModel)]="dateStart" ngbDatepicker #d1="ngbDatepicker" required>
                              <button class="btn btn-sm btn-sm-date-picker btn-outline-secondary" type="button" (click)="d1.toggle()">
                                <i class="fa-regular fa-calendar" aria-hidden="true"></i>
                              </button>
                            </div>
                        </div>
                        <div class="controls col-md-4">
                            <div class="input-group">
                                <input class="form-control form-control-sm" placeholder="yyyy-mm-dd" name="dp2"
                                       [(ngModel)]="dateEnd" ngbDatepicker #d2="ngbDatepicker" required>
                              <button class="btn btn-sm btn-sm-date-picker btn-outline-secondary" type="button" (click)="d2.toggle()">
                                <i class="fa-regular fa-calendar" aria-hidden="true"></i>
                              </button>
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="member_status">Membership Status</label>
                        <div class="controls col-md-8">

                          <select id="member_status" name="member_status" class="form-control form-control-sm" [(ngModel)]="searchModel.member_status">
                              <option value=""></option>
                              <optgroup label="Groups">
                                  <option value="active,manual,thin_file">Active Account (active,manual,thin_file)</option>
                              </optgroup>
                              <optgroup label="Specific Status">
                                  <option *ngFor="let status of memberstatusOptions" [value]="status">{{status}}</option>
                              </optgroup>
                          </select>

                          <span id="errId8" class="error"></span>
                        </div>

                    </div>


                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="phone">Phone</label>
                        <div class="controls col-md-8">
                            <input id="phone" name="phone" class="form-control form-control-sm"
                                   type="text" [(ngModel)]="searchModel.phone">
                        </div>

                    </div>

                    <div class="form-group row">
                        <label class="control-label control-label-left col-md-4" for="dp3">Transaction Date Range</label>
                        <div class="controls col-md-4">
                            <div class="input-group">
                                <input class="form-control form-control-sm" placeholder="yyyy-mm-dd" name="dp3" id="dp3"
                                       [(ngModel)]="transactionDateStart" ngbDatepicker #d3="ngbDatepicker" required>
                              <button class="btn btn-sm btn-sm-date-picker btn-outline-secondary" type="button" (click)="d3.toggle()">
                                <i class="fa-regular fa-calendar" aria-hidden="true"></i>
                              </button>
                            </div>
                        </div>
                        <div class="controls col-md-4">
                            <div class="input-group">
                                <input class="form-control form-control-sm" placeholder="yyyy-mm-dd" name="dp4"
                                       [(ngModel)]="transactionDateEnd" ngbDatepicker #d4="ngbDatepicker" required>
                              <button class="btn btn-sm btn-sm-date-picker btn-outline-secondary" type="button" (click)="d4.toggle()">
                                <i class="fa-regular fa-calendar" aria-hidden="true"></i>
                              </button>
                            </div>
                        </div>
                    </div>

                  <!-- CID -->
                  <div class="form-group row">
                    <label class="control-label control-label-left col-md-4" for="cid">CID</label>
                    <div class="controls col-md-8">
                      <input class="form-control form-control-sm" name="cid" id="cid"
                             [(ngModel)]="searchModel.cid">
                    </div>
                  </div>

                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="search-buttons float-end">
                        <button type="submit" (click)="submit()" class="btn btn-sm btn-outline-primary me-2">Search</button>
                        <button type="button" class="btn btn-sm btn-outline-secondary" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<div class="container"  *ngIf="customers.length > 0">
    <!--<hr class="separator">-->
    <h5 class="margin-top-35">Search Results:</h5>
    <div class="card card-body rs-card rs-card-blue">

        <div class="color-legend">
            <!--<h5>Legend</h5>-->
            <div class="row">
                <div class="col-md-3">
                    <div class="color-legend-item">
                        <i class="fa-solid fa-ban font-cancelled" aria-hidden="true"></i>
                        <label>Cancelled</label>
                    </div>
                    <div class="color-legend-item">
                        <i class="fa-solid fa-triangle-exclamation font-escalated" aria-hidden="true"></i>
                        <label>Escalated</label>
                    </div>
					<div class="color-legend-item">
						<i class="fa-solid fa-magnifying-glass font-thin-file" aria-hidden="true"></i>
						<label>Thin File</label>
					</div>
                </div>
                <div class="col-md-3">
                    <div class="color-legend-item">
                        <i class="fa-solid fa-user font-prospect" aria-hidden="true"></i>
                        <label>Prospect</label>
                    </div>
                    <div class="color-legend-item">
                        <i class="fa-regular fa-credit-card font-chargeback" aria-hidden="true"></i>
                        <label>Chargeback</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="color-legend-item">
                        <i class="fa-solid fa-up-right-from-square font-extended-trial" aria-hidden="true"></i>
                        <label>Extended Trial</label>
                    </div>
                    <div class="color-legend-item">
                        <i class="fa-solid fa-dollar-sign font-freecredit" aria-hidden="true"></i>
                        <label>FreeCreditRatings</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="color-legend-item">
                        <i class="fa-solid fa-retweet font-return-reversal" aria-hidden="true"></i>
                        <label>Return Reversal</label>
                    </div>
                    <div class="color-legend-item">
                        <i class="fa-solid fa-magnifying-glass font-manual-verify" aria-hidden="true"></i>
                        <label>Manual Verification</label>
                    </div>
                </div>
            </div>
        </div>

    <div class="row">
        <div class="col-sm-12">
            <table class="table table-sm  table-search-results">
                <thead class="">
                <tr>
                    <th class="text-center">Status</th>
                    <th>First Name</th>
                    <th>Surname</th>
                    <th class="d-md-none d-lg-table-cell">City</th>
                    <th>Post Code</th>
                    <th>Email</th>
                    <th>
                      Last Four
                      <i class="fa-regular fa-circle-question d-inline-block" ngbTooltip="If a direct debit customer, this will be the last four of the account number" container="body"></i>
                    </th>
                    <th>Product</th>
                    <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let customer of customers"
                    [ngClass]="{freecredit: customer.afid === 420227,
                    'manual-verify': customer.status === 'manual',
                    'return-reversal': customer.status === 'returnreverse',
                    'chargeback': customer.status === 'chargeback',
                    prospect: customer.status === 'prospect',
                    cancelled: customer.status === 'cancelled',
                    escalated: customer.escalated,
                    'thin-file': customer.status === 'thin_file'}">

                    <td [ngSwitch]="customer.status" class="text-center">
                        <i class="fa-solid fa-ban font-cancelled" aria-hidden="true" *ngSwitchCase="'cancelled'"></i>
                        <i class="fa-solid fa-retweet font-return-reversal" aria-hidden="true" *ngSwitchCase="'returnreverse'"></i>
                        <i class="fa-regular fa-credit-card font-chargeback" aria-hidden="true" *ngSwitchCase="'chargeback'"></i>
                        <i class="fa-solid fa-user font-prospect" aria-hidden="true" *ngSwitchCase="'prospect'"></i>
                        <i class="fa-solid fa-magnifying-glass font-manual-verify" aria-hidden="true" *ngSwitchCase="'manual'"></i>
                        <i class="fa-solid fa-magnifying-glass font-thin-file" aria-hidden="true" *ngSwitchCase="'thin_file'"></i>

                        <i class="fa-solid fa-up-right-square font-extended-trial" aria-hidden="true" *ngIf="customer.extended_trial"></i>

                        <i class="fa-solid fa-dollar-sign font-freecredit" aria-hidden="true" *ngIf="customer.afid === 420227"></i>
                        <i class="fa-solid fa-triangle-exclamation font-escalated" aria-hidden="true" *ngIf="customer.escalated"></i>
                    </td>
                    <td>{{ customer.fname}}</td>
                    <td>{{ customer.lname}}</td>
                    <td class="d-md-none d-lg-table-cell">{{ customer.city}}</td>
                    <td>{{ customer.zip}}</td>
                    <td>{{ customer.email}}</td>
                    <td>{{ customer.last_four}}</td>
                    <td>{{ customer.product_name}}</td>
                    <td class="text-center" [ngSwitch]="customer.status">
                      <a *ngSwitchCase="'prospect'" [routerLink]="['/prospect/detail/', customer.id, customer.guid]" [title]="customer.guid">
                        <i class="fa-solid fa-eye"></i>
                      </a>
                      <a *ngSwitchDefault [routerLink]="['/customer/detail', customer.guid]" [title]="customer.guid">
                        <i class="fa-solid fa-eye"></i>
                      </a>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
    </div>
    </div>
</div>

<!--<pre *ngIf="customers.length > 0">{{ customers | json }}</pre>-->
