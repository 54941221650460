import {Observable, of as observableOf} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Reason} from '../../models/reason.model';

@Injectable({providedIn: 'root'})
export class CancelReasonService {

    private resourceUrl = '/api/account/reasons';

    public reasons: Array<Reason>;


    constructor(private http: HttpClient) {
        this.reasons = [];
    }

    /**
     * Fetch customer from server and cache locally
     * @param guid
     * @returns {Observable<T>}
     */
    fetch(): Observable<Array<Reason>> {
        return this.http
            .post(this.resourceUrl, {group: 'cancel'}).pipe(
            map(
                resp => resp as Array<Reason>
            ),
            tap(
                resp => {
                    this.reasons = resp as Array<Reason>;
                }
            ), );
    }


    /**
     * Retrieve Customer from cache or fetch from server if not found
     * @param guid
     * @param force
     * @returns {any}
     */
    get(force?: boolean): Observable<Array<Reason>> {

        if (this.reasons.length == 0 || force === true) {
            return this.fetch();
        }

        return observableOf(this.reasons).pipe(map(
            resp => resp as Array<Reason>
        ));
    }
}
