import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoginLogService} from '../../services/login-log.service';
import {LengthAwarePaginator} from '../../../shared/models/LengthAwarePaginator';
import {CustomerLogin} from "../../../shared/models/customer-login.model";
import { NgbTooltip, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgSwitch, NgSwitchCase, NgIf, NgSwitchDefault } from '@angular/common';
import { DateTimeFormatPipe } from '../../../shared/pipes/date-time-format.pipe';

@Component({
    selector: 'app-customer-logins',
    templateUrl: 'customer-logins.component.html',
    styleUrls: ['customer-logins.component.css'],
    standalone: true,
    imports: [NgbTooltip, NgFor, NgSwitch, NgSwitchCase, NgIf, NgSwitchDefault, NgbPagination, DateTimeFormatPipe]
})
export class CustomerLoginsComponent implements OnInit {

  private guid = '';
  public logins: LengthAwarePaginator<CustomerLogin>;

  constructor(
    private loginLogService: LoginLogService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    // Watch for GUID changes
    this.route.params.subscribe(
      params => {
        this.guid = this.route.snapshot.params['guid'];
        this.fetchLogins(1);
      }
    );
  }

  onPageChange(page) {
    this.fetchLogins(page);
  }

  fetchLogins(page) {
    this.loginLogService.get(this.guid, page).subscribe(lengthAwarePaginator => {
      this.logins = lengthAwarePaginator;
    });
  }

}
