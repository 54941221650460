import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MailEventService} from '../../services/mail-event.service';
import {MailEvent} from '../../models/mail-event.model';
import { NgbModal, NgbTooltip, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import {MailEventsModalComponent} from "../../mail-events-pdf-modal/mail-events-modal.component";
import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, SlicePipe } from '@angular/common';
import { DateTimeFormatPipe } from '../../../shared/pipes/date-time-format.pipe';


@Component({
    selector: 'app-mail-events',
    templateUrl: './mail-events.component.html',
    styleUrls: ['./mail-events.component.css'],
    standalone: true,
    imports: [NgbTooltip, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, NgbPagination, SlicePipe, DateTimeFormatPipe]
})
export class MailEventsComponent implements OnInit {

  public mailEvents: Array<MailEvent>;
  /**
   * Paginate the mail events
   * @type {start: number; end: number; current_page: number}
   */
  public pagination: any = {
    start: 0,
    end: 10,
    current_page: 1
  };

  /**
   * Tooltips keyed by mail event type
   * @type {clicked: string; delivered: string; dropped: string; bounced: string; opened: string; complained: string; unsubscribed: string}
   */
  public info = {
    clicked: 'Every time a user clicks on a link in your messages',
    delivered: 'A successful delivery occurs when the recipient email server responds that it has accepted the message',
    dropped: 'The mail server stops attempting to deliver messages and drops them. This could be due to: hard bounces, messages that reached their retry limit, previously unsubscribed/bounced/complained addresses, or addresses rejected by an ESP',
    bounced: 'When the recipient email server specifies that the recipient address does not exist',
    opened: 'Every time a user opens one of your messages',
    complained: 'When a user reports one of your emails as spam. Note that not all ESPs provide this feedback',
    unsubscribed: 'When a user unsubscribes, either from all messages, a specific tag or a mailing list',
  };


  constructor(
    private route: ActivatedRoute,
    private mailEventService: MailEventService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    // Watch for GUID changes
    this.route.params.subscribe(
      params => {
        this.fetchMailEvents(params['guid']);
      }
    );
  }

  onPageChange(page) {
    this.pagination.current_page = page;
    this.pagination.start = page * 10 - 10;
    this.pagination.end = page * 10;
  }

  private fetchMailEvents(guid) {
    this.mailEventService.get(guid).subscribe(mailEvents => {
      this.mailEvents = mailEvents;
    });
  }

  showPdfTemplate(tag: string) {
    const tags = tag.split(',');

    if (tags.includes('welcome') && tags.includes('K') && tags.includes('VC')) {
      this.loadPdfFile('welcome-K-VC.pdf');
    } else if (tags.includes('welcome') && tags.includes('K') && tags.includes('NVC')) {
      this.loadPdfFile('welcome-K-NVC.pdf');
    } else if (tags.includes('welcome') && tags.includes('NK') && tags.includes('VC')) {
      this.loadPdfFile('welcome-NK-VC.pdf');
    } else if (tags.includes('welcome') && tags.includes('NK') && tags.includes('NVC')) {
      this.loadPdfFile('welcome-NK-NVC.pdf');
    } else if (tags.includes('alert')) {
      this.loadPdfFile('credit-update.pdf');
    } else if (tags.includes('cancellation')) {
      this.loadPdfFile('cancellation.pdf');
    } else if (tags.includes('MV_start')) {
      this.loadPdfFile('mv-start.pdf');
    } else if (tags.includes('MV2')) {
      this.loadPdfFile('mv-2.pdf');
    } else if (tags.includes('MV3')) {
      this.loadPdfFile('mv-3.pdf');
    } else if (tags.includes('thin_file_start')) {
      this.loadPdfFile('thin-file-start.pdf');
    } else if (tags.includes('darkwebalert')) {
      this.loadPdfFile('new-darkweb-threats.pdf');
    }
  }

  allowedTags(tag: string) {
    // Check if the tag is one of the allowed ones and set the flag accordingly
    return [
      'welcome',
      'alert',
      'cancellation',
      'MV_start',
      'MV2',
      'MV3',
      'thin_file_start',
      'darkwebalert',
    ].some(value => tag.includes(value));
  }

  loadPdfFile(pdfFileName: string) {
    const pdfUrl = `/assets/mail-events-pdf/${pdfFileName}`;
    const modalRef = this.modalService.open(MailEventsModalComponent, {size: 'lg'});
    modalRef.componentInstance.pdfUrl = pdfUrl;
  }
}
