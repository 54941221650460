import {Component, Input} from '@angular/core';
import {DineSubscriptionInterface} from '../../models/dine-subscription.interface';
import {DineCardService} from '../../services/dine-card.service';
import { NgIf } from '@angular/common';
import { CapitalizePipe } from '../../../shared/pipes/capitalize.pipe';

@Component({
    selector: 'app-dine-incentive',
    templateUrl: './dine-incentive.component.html',
    standalone: true,
    imports: [NgIf, CapitalizePipe]
})
export class DineIncentiveComponent {

  public dineSubscription: DineSubscriptionInterface = null;
  private _guid = '';

  constructor(public dineCardservice: DineCardService) {
  }

  get guid(): string {
    return this._guid;
  }

  @Input()
  set guid(value: string) {
    this._guid = value;

    this.loadDineSubscriptionInfo();
  }

  /**
   * Pull information on the Dine Card incentive.  This will let us know if they subscribed at all and
   * what state it's in.
   * @private
   */
  private loadDineSubscriptionInfo(): void {
    this.dineCardservice
      .dineCardSubscriptionInfo(this._guid)
      .subscribe(
        resp => {
          // If it is an empty response, there is no subscription.
          if (!resp.hasOwnProperty('status')) {
            this.dineSubscription = null;
            return;
          }

          this.dineSubscription = resp;
        }
      );
  }
}
