import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReportPostLogInterface} from "../../models/report-post-log.interface";
import {ReportPostLogService} from "../../services/report-post-log.service";
import {LengthAwarePaginator} from "../../../shared/models/LengthAwarePaginator";
import { NgClass, NgIf, NgFor, NgSwitch, NgSwitchCase, SlicePipe } from '@angular/common';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeFormatPipe } from '../../../shared/pipes/date-time-format.pipe';


@Component({
    selector: 'app-report-post-log',
    templateUrl: 'report-post-log.component.html',
    styleUrls: ['report-post-log.component.css'],
    standalone: true,
    imports: [NgClass, NgIf, NgFor, NgSwitch, NgSwitchCase, NgbPagination, SlicePipe, DateTimeFormatPipe]
})
export class ReportPostLogComponent implements OnInit {

  @Input() uid;

  public reportPostLog:  LengthAwarePaginator<ReportPostLogInterface>;

  public pagination: any = {
    start: 0,
    end: 10,
    current_page: 1
  };


  constructor(
    private route: ActivatedRoute,
    private reportPostLogService: ReportPostLogService
  ) {
  }

  ngOnInit() {
    // Watch for GUID changes
    this.route.params.subscribe(
      params => {
        this.fetchReportPostLog(1);
      }
    );
  }

  onPageChange(page) {
    this.fetchReportPostLog(page);
  }

  private fetchReportPostLog(page): void {
    this.reportPostLogService.get(this.uid, page).subscribe(lengthAwarePaginator => {
      this.reportPostLog = lengthAwarePaginator;
    });
  }

  public isSuccess(status: string): boolean {
    return (status === 'SUCCESS') ? true : false;
  }
}
