import {enableProdMode, ErrorHandler, importProvidersFrom} from '@angular/core';
import * as Sentry from '@sentry/angular';

import {environment} from './environments/environment';
import {appErrorFactory, AppErrorHandler} from 'src/app/shared/error/app-error-handler.service';
import {AllCommunityModule, ModuleRegistry} from 'ag-grid-community';
import {provideRouter} from '@angular/router';
import {routes} from 'src/app/app-routes';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideNgxWebstorage, withLocalStorage, withSessionStorage} from 'ngx-webstorage';
import {APP_CONFIG, AppConfig} from 'src/app/app.config';
import {ErrorService} from 'src/app/shared/error/error.service';
import {LoadIndicatorInterceptor} from '@ratespecial/core';
import {AuthSanctumInterceptor} from 'src/app/shared/interceptor/http/auth-sanctum.interceptor';
import {LogoutOnUnauthInterceptor} from 'src/app/shared/interceptor/http/logout-on-unauth.interceptor';
import {ServerErrorInterceptor} from 'src/app/shared/interceptor/http/server-error.interceptor';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {HotkeyModule, IHotkeyOptions} from 'angular2-hotkeys';
import {provideStore} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {AppComponent} from './app/app.component';

const iHotkeyOptions: IHotkeyOptions = {

  disableCheatSheet: false,
  // cheatSheetHotkey: 'shift+h',
  // cheatSheetCloseEsc: true,
  // cheatSheetCloseEscDescription: 'Close Help Menu',
  cheatSheetDescription: 'Show/Hide this Help Menu',
};


if (environment.production) {
  enableProdMode();
}

if (environment.production) {
  Sentry.init({
    dsn: 'https://b1a2615fe1654fa6b6f361cd758297a6@o401842.ingest.us.sentry.io/5282134',
    release: environment.release,
    environment: environment.sentryEnvironment,

    beforeBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint?: Sentry.BreadcrumbHint): Sentry.Breadcrumb | null {
      // If this is a navigation breadcrumb, apply our own filters to make sure sensitive data doesn't escape
      if ('category' in breadcrumb && breadcrumb.category === 'navigation') {
        Object.keys(AppErrorHandler.navigationDataFilters).forEach((key => {
          if (breadcrumb.data['from'].indexOf(key) > -1) {
            breadcrumb.data['from'] = AppErrorHandler.navigationDataFilters[key];
          }

          if (breadcrumb.data['to'].indexOf(key) > -1) {
            breadcrumb.data['to'] = AppErrorHandler.navigationDataFilters[key];
          }
        }));
      }

      return breadcrumb;
    },

    beforeSend(event) {
      // Discard 401 and 403 events
      if (event && event.message && (
        event.message.includes('401 Unauthorized')
        || event.message.includes('403 Forbidden')
        || event.message.includes('401 OK')
      )) {

        return null;
      }

      return event;
    },

    ignoreErrors: [
      '0 Unknown Error',  // Seems to happen to a lot of calls at once on mobile users.  network issue?
      "Cannot read properties of undefined (reading 'domInteractive')", // https://github.com/getsentry/sentry-javascript/issues/10011
    ],
  });
}

ModuleRegistry.registerModules([AllCommunityModule]);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, HotkeyModule.forRoot(iHotkeyOptions)),
    provideStore([
      SessionState, CustomerState,
    ], {
      developmentMode: !environment.production,
    }),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(withLocalStorage(), withSessionStorage()),

    // Use our custom AppErrorHandler to process Angular errors
    {provide: ErrorHandler, useFactory: appErrorFactory},
    // Alias AppErrorHandler to the ErrorHandler token so we can inject AppErrorHandler and configure it
    {provide: AppErrorHandler, useExisting: ErrorHandler},
    {provide: APP_CONFIG, useValue: AppConfig},

    ErrorService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadIndicatorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthSanctumInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutOnUnauthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
})
  .catch(err => console.error(err));
