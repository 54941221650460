import {Component} from '@angular/core';
import {Hotkey, HotkeysService, HotkeyModule} from 'angular2-hotkeys';
import {GuidSearchModalComponent} from './hotkey/guid-search-modal/guid-search-modal.component';
import {CustomerSearchModalComponent} from './hotkey/customer-search-modal/customer-search-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {AuthService} from 'src/app/shared/auth/services/auth.service';
import {UserService} from 'src/app/shared/auth/services/user.service';
import {Store} from '@ngxs/store';
import * as SessionActions from 'src/app/shared/state/session.actions';
import {AlertsComponent, LoadIndicatorComponent} from '@ratespecial/core';
import {RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    AlertsComponent,
    LoadIndicatorComponent,
    HotkeyModule,
    RouterOutlet,
  ],
})
export class AppComponent {


  constructor(private _hotkeysService: HotkeysService,
              private modalService: NgbModal,
              private authService: AuthService,
              private userService: UserService,
              private store: Store) {

    this.registerHotkeyListeners();
    this.watchAuthenticationChanges();
  }

  private watchAuthenticationChanges(): void {
    this.authService.isAuthenticated().subscribe(loggedIn => {
      if (loggedIn) {
        this.userService.fetch().subscribe({error: e => console.error(e)});
      } else {
        this.store.dispatch(new SessionActions.ClearState());
      }
    })
  }

  /**
   * Reference to find by guid modal
   */
  private static guidModalRef: NgbModalRef = null;

  /**
   * Reference to customer search modal
   */
  private static customerSearchModalRef: NgbModalRef = null;

  private registerHotkeyListeners() {

    // Find By GUID
    this._hotkeysService.add(
      new Hotkey(
        'shift+f',
        (event: KeyboardEvent): boolean => {
          // Prevent opening multiple
          if (AppComponent.guidModalRef === null) {
            this.openGuidSearchModal();
          }
          return false; // Prevent bubbling
        },
        [],
        'Find by GUID',
      ),
    );


    // Search Customer
    this._hotkeysService.add(
      new Hotkey(
        'shift+s',
        (event: KeyboardEvent): boolean => {
          // Prevent opening multiple
          if (AppComponent.customerSearchModalRef === null) {
            this.openSearchCustomerModal();
          }
          return false; // Prevent bubbling
        },
        [],
        'Customer Search',
      ),
    );
  }


  /**
   * Pop Modal for Search by GUID
   */
  private openGuidSearchModal() {
    AppComponent.guidModalRef = this.modalService.open(GuidSearchModalComponent);

    AppComponent.guidModalRef
      .result
      .finally(() => AppComponent.guidModalRef = null);
  }


  /**
   * Pop Search Customer Modal
   */
  private openSearchCustomerModal() {
    AppComponent.customerSearchModalRef = this.modalService.open(CustomerSearchModalComponent);

    AppComponent.customerSearchModalRef
      .result
      .finally(() => AppComponent.customerSearchModalRef = null);
  }
}
