import {Component, Input} from '@angular/core';
import {CustomerService} from "../../services/customer.service";
import {AccountSettings} from "../../../shared/models/account-settings.interface";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SmartOffersInfoModalComponent} from "../../smart-offers-info-modal/smart-offers-info-modal.component";

@Component({
    selector: 'app-smart-offers-incentive',
    templateUrl: './smart-offers-incentive.component.html',
    styleUrls: ['./smart-offers-incentive.component.scss'],
    standalone: true
})
export class SmartOffersIncentiveComponent {
  settings: AccountSettings;

  get uid(): number {
    return this._uid;
  }

  @Input()
  set uid(value: number) {
    this._uid = value;

    this.loadInfo();
  }

  private _uid = 0;

  constructor(private customerService: CustomerService,
              private modalService: NgbModal) { }


  private loadInfo(): void {
    this.customerService
      .getUserSettings(this._uid)
      .subscribe(settings => this.settings = settings);
  }

  showModal(): void {
    const modalRef = this.modalService.open(SmartOffersInfoModalComponent);
    modalRef.componentInstance.accountSettings = this.settings;
  }
}
