import {distinctUntilChanged, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AlertService} from "@ratespecial/core";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthCheckResponse} from 'src/app/shared/models/auth-check-response.interface';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import * as SessionActions from 'src/app/shared/state/session.actions';

@Injectable({providedIn: 'root'})
export class AuthService {


    constructor(private http: HttpClient,
                private router: Router,
                private alertService: AlertService,
                private modalService: NgbModal,
                private store: Store,
    ) {
    }


    /**
     * Logout - Clear auth tokens, save last route and kick user back to login page
     */
    logout(showExpirationError = false) {
      this.modalService.dismissAll();

      this.http.get('/api/auth/logout').subscribe(() => {
        this.store.dispatch(new SessionActions.UpdateIsAuthenticated(false))

        this.router.navigateByUrl('/auth/login');

        if (showExpirationError) {
          this.alertService.error('Your session has expired.  Please log in.');
        }
      });
    }


    /**
     * To be called after the user returns from google's oauth page.  Google will
     * pass us a code, that we must validate server side.
     * @param code
     * @returns {Observable<any>}
     */
    authenticateGoogleCode(code) {
        const postData = {code: code};
        return this.http.post('/api/auth/google', postData);
    }


    /**
     * Set our Sanctum csrf cookie value to be used later
     */
    setCsrfCookie() {
        return this.http.get('/api/csrf-cookie');
    }


    /**
     * Check if current user is logged in
     */
    checkUser(): Observable<boolean> {
      return this.http
        .get<AuthCheckResponse>('/api/auth/check')
        .pipe(
          map(resp => resp.logged_in),
          tap(loggedIn => this.store.dispatch(new SessionActions.UpdateIsAuthenticated(loggedIn))),
        );
    }


    /**
     * Local Login
     * @param loginRequest
     * @returns {Observable<void>}
     */
    localLogin(loginRequest) {
        return this.http.post('/api/auth/local', loginRequest);
    }

    isAuthenticated(): Observable<boolean> {
      return this.store
        .select(SessionState.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
        );
    }

  hasPermission(permissionName: string): boolean {
    const userPermissions = this.store.selectSnapshot(SessionState.getPermissions);
    return userPermissions && userPermissions.includes(permissionName);
  }


}
