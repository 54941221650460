import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SmsEventService} from '../../services/sms-event.service';
import {SmsEvent} from '../../models/sms-event.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { NgClass, NgIf, NgFor, SlicePipe } from '@angular/common';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeFormatPipe } from '../../../shared/pipes/date-time-format.pipe';


@Component({
    selector: 'app-sms-events',
    templateUrl: './sms-events.component.html',
    styleUrls: ['./sms-events.component.css'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgFor,
        NgbPagination,
        SlicePipe,
        DateTimeFormatPipe,
    ],
})
export class SmsEventsComponent {

  smsEvents: Array<SmsEvent>;

  pagination: { start: number; end: number; current_page: number } = {
    start: 0,
    end: 10,
    current_page: 1,
  };


  constructor(
    private route: ActivatedRoute,
    private SmsEventService: SmsEventService,
  ) {
    this.route.params
      .pipe(
        takeUntilDestroyed(),
      )
      .subscribe(
        params => {
          this.fetchSmsEvents(params['guid']);
        },
      );
  }

  onPageChange(page: number): void {
    this.pagination.current_page = page;
    this.pagination.start = page * 10 - 10;
    this.pagination.end = page * 10;
  }

  private fetchSmsEvents(guid: string) {
    this.SmsEventService.get(guid).subscribe(smsEvents => {
      this.smsEvents = smsEvents;
    });
  }
}
