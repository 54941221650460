import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {CustomerLite} from '../../models/customer-lite.model';
import {CancelReasonService} from '../services/cancel-reason.service';
import {AccountService} from '../services/account.service';
import {ErrorService} from '../../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";
import { ErrorComponent } from '../../../shared/error/error.component';
import { FormsModule } from '@angular/forms';
import { NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-cancel-account-modal',
    templateUrl: './cancel-account-modal.component.html',
    styleUrls: ['./cancel-account-modal.component.scss'],
    standalone: true,
    imports: [ErrorComponent, FormsModule, NgClass, NgFor]
})
export class CancelAccountModalComponent implements OnInit {

  /**
   * Customer Data needed for request
   */
  public customer: CustomerLite;

  /**
   * Reason select options
   */
  public reasons: Array<any> = [];

  public ajaxWorking = false;

  public cancelAccountRequest = {
    guid: null,
    reason: null,
    note: null
  };

  // Add reference to Object.keys function to template
  public objectKeys = Object.keys;


  constructor(
    private reasonService: CancelReasonService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    public errorService: ErrorService
  ) {
  }


  ngOnInit() {
    this.errorService.clearErrors();

    // Get Reasons
    this.reasonService
      .get()
      .subscribe(data => {
        this.reasons = data;
      });
  }

  cancelAccount() {
    this.errorService.clearErrors();
    this.cancelAccountRequest.guid = this.customer.guid;

    this.ajaxWorking = true;

    this.accountService.cancelAccount(this.cancelAccountRequest)
      .subscribe(
        (data) => {
          this.alertService.success('Account has been cancelled');
          this.close();
          this.ajaxWorking = false;
        },
        (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
          this.ajaxWorking = false;
        }
      );
  }

  close() {
    this.activeModal.close();
  }
}
