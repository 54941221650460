import {RedirectCommand, ResolveFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {catchError, of, switchMap} from 'rxjs';
import {RentReportingInfo} from 'src/app/rent-reporting/models/rent-declaration-info';
import {AlertService} from '@ratespecial/core';
import {Store} from '@ngxs/store';
import {UpdateRentInfo} from 'src/app/shared/state/customer.actions';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {UserService} from 'src/app/shared/auth/services/user.service';

export const rentPageRentInfoResolver: ResolveFn<RentReportingInfo | null> = (route, state) => {
  const store = inject(Store);
  const alertService = inject(AlertService);
  const router = inject(Router);
  const userService = inject(UserService);

  const guid = route.params['guid'];

  // Start by checking user permissions
  return userService.hasAbility('rent-reporting')
    .pipe(
      switchMap(hasPermission => {
        // If no permissions, don't fetch any rent reporting info.  If we try, the server will return 403.
        if (!hasPermission) {
          return of(null);
        }

        // This will ensure the guid is in the state properly.  May or may not API call
        return store.dispatch(new UpdateRentInfo(guid));
      }),

      // store.dispatch returns void.  Go fetch the actual data
      switchMap(() => store.select(CustomerState.getRentInfo)),

      // If anything goes wrong
      catchError(e => {
        console.error(e);

        if (e.status == 422) {
          alertService.error('Invalid GUID probably');
        }

        // Send us back to the dashboard
        return of(new RedirectCommand(router.parseUrl('/')));
      }),
    );
};
