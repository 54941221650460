import {CustomerInterface} from './customer.interface';
import {CustomerLite} from './customer-lite.model';
import {TransactionModel} from 'src/app/shared/models/transaction.model';
import {AddressHistory} from 'src/app/shared/models/address-history';
import {CustomerThreedsInterface} from './customer-threeds.interface';
import {Agency} from 'src/app/customer/models/agency';
import {AgencyCustomerInterface} from 'src/app/customer/models/agency-customer.interface';
import {Screenshot} from 'src/app/customer/models/screenshot';
import {DateTime} from 'luxon';

export class Customer implements CustomerInterface {

  public abodenumber = '';
  public address_history: Array<AddressHistory> = [];
  public afid = 0;
  public agencies: { [key in Agency]: AgencyCustomerInterface }
  public alerts: any = {};
  public bsid = 0;
  public building_name = '';
  public cid = 0;
  public city = '';
  public country = '';
  public dob: any = '';
  public dt = '';
  public email = '';
  public escalated: number;
  public extended_trial: number;
  public extended_trial_date = '';
  public flow_type = '';
  public fname = '';
  public free_trial_days = 7;
  public guid = '';
  public in_trial = false;
  public initial_price = '';
  public internal_desc = '';
  public ip_addr = '';
  public is_manual_verify = false;
  public is_thin_file = false;
  public is_broken_enroll = false;
  public is_pii_cleared = false;
  public is_purgatory = false;
  public last_activity = '';
  public last_login = '';
  public last_four = '';
  public lname = '';
  public limelight_gateway_id = 0;
  public limelight_gateway_name = '';
  public member: any = {};
  public num_payments = 0;
  public phone = '';
  public processor_name = '';
  public proc_id = 0;
  public product_id = '';
  public product_name = '';
  public recurring_interval = '';
  public recurring_price = '';
  public sid = '';
  public screenshots: Array<Screenshot> = [];
  public staging = false;
  public start_dt = '';
  public status = '';
  public straddr = '';
  public street = '';
  // `user_data`.`tags`
  tags: { [key: string]: boolean };
  public transactions: Array<TransactionModel> = [];
  public trial_end_date = '';
  public vertical = 'N/A';
  public zip = '';
  public addmarket = 0;
  public cc_guid = '';
  public uid = 0;
  public deferred_auth = 0;
  public deferred_auth_complete = 0;
  public threeds: CustomerThreedsInterface|null = null;
  public third_party_data: any = {};
  final_day_of_access = undefined;
  date_cancelled = undefined;

  constructor(data: Partial<Customer>) {
    Object.assign(this, data);
  }


  /**
   * Returns the basic customer without the nested transactions or member data
   */
  getCustomerLite(): CustomerLite {
    const c = new CustomerLite();
    c.fromJSON(this);
    return c;
  }

  hasAgency(agency: Agency): boolean {
    return agency in this.agencies;
  }

  get fullname(): string {
    return `${this.fname} ${this.lname}`.trim();
  }

  get finalDayOfAccessDt(): DateTime|null {
    if (! this.final_day_of_access) {
      return null;
    }

    return DateTime.fromISO(this.final_day_of_access);
  }

  /**
   * If the customer was a no match when registering at the credit agency
   */
  isNoMatch(): boolean {
    return this.tags?.isNoMatch ?? false;
  }

}
