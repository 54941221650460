import {inject} from '@angular/core';
import {tap} from 'rxjs/operators';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from 'src/app/shared/auth/services/user.service';
import {AlertService} from '@ratespecial/core';

/**
 * PermissionGuard checks if the user has the required permission.
 *
 * Example:
 *     {
 *         path: 'admin',
 *         component: AdminComponent,
 *         canActivate: [PermissionGuard],
 *         data: {
 *             permission: 'admin:access'  // Permission required for this route
 *         }
 *     },
 */
export const PermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const userService = inject(UserService);
  const router = inject(Router);
  const alertService = inject(AlertService);

  // Get the permission name(s) from the route's data
  const requiredPermission = route.data['permission'];

  if (!requiredPermission) {
    throw new Error('PermissionGuard: missing required permission');
  }

  return userService.hasAbility(requiredPermission).pipe(
    tap(hasPermission => {
      if (!hasPermission) {
        alertService.error('You do not have permission to access this page.');

        router.navigate(['/dashboard']);
      }
    })
  );
};
