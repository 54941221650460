import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricepointService } from '../services/pricepoint.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from "@ratespecial/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Pricepoint } from 'src/app/customer/pricepoint/model/pricepoint';
import { UpdatePricepointRequest } from 'src/app/customer/pricepoint/model/update-pricepoint-request';
import { UserService } from 'src/app/shared/auth/services/user.service';

@Component({
  selector: 'app-downsell-modal',
  templateUrl: './downsell-modal.component.html',
  styleUrls: ['./downsell-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
})
export class DownsellModalComponent implements OnInit, OnDestroy {

  pricepoints: Pricepoint[] = [];
  selectedPricepoint: Pricepoint;
  recurring_price: string = '';

  canMakeAccountFree = false;

  public form: UpdatePricepointRequest = {
    bsid: null,
    guid: null,
    recurring_price: null,
  };

  private subscription: Subscription;

  constructor(
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    private pricepointService: PricepointService,
    private userService: UserService,
  ) {
    // Check for permission using UserService
    this.canMakeAccountFree = this.userService.hasAbilitySnapshot('make-account-free', this.userService.getSnapshot());
  }

  ngOnInit(): void {
    this.subscription = this.pricepointService.get()
      .subscribe(data => {
        this.pricepoints = data;
        this.form.bsid = this.getBsidForCurrentPrice();
      });
  }

  private getBsidForCurrentPrice() {
    return this.pricepoints.find(pp => pp.recurring_price === this.recurring_price)?.bsid;
  }

  onSelectionChange(pricepoint: Pricepoint) {
    this.selectedPricepoint = pricepoint;
    this.form.recurring_price = pricepoint.recurring_price;
    this.form.bsid = pricepoint.bsid;
  }

  save() {
    const isFree = parseInt(this.selectedPricepoint.recurring_price) === 0;

    // Check if user has ability to make account free if attempting to make it free
    if (isFree && !this.canMakeAccountFree) {
      this.alertService.error('You do not have permission to make accounts free');
      return;
    }

    this.pricepointService.updateAccountPricing(this.form.guid, this.form.bsid, this.form.recurring_price)
      .subscribe({
        next: () => {
          this.alertService.success(isFree ? 'Account has been made free' : 'Customer Pricepoint Updated');
          this.close();
        },
        error: (err: HttpErrorResponse) => {
          this.handleValidationErrors(err);
        },
      });
  }

  close() {
    this.activeModal.close();
  }

  handleValidationErrors(err) {
    if (err.status == 422) {
      err.error.errors.forEach(error => {
        this.alertService.addApiError(error);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
