import {Component} from '@angular/core';
import {NavigationStart, Router, RouterLink} from '@angular/router';
import {CustomerService} from '../services/customer.service';
import {
  NgbDropdown,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbModal,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import {CustomerEditModalComponent} from '../customer-edit-modal/customer-edit-modal.component';
import {ChangeAfidModalComponent} from '../change-afid-modal/change-afid-modal.component';
import {Customer} from '../models/customer.model';
import {UserService} from '../../shared/auth/services/user.service';
import {DownsellModalComponent} from '../pricepoint/downsell-modal/downsell-modal.component';
import {PasswordResetModalComponent} from '../account/password-reset-modal/password-reset-modal.component';
import {CancelAccountModalComponent} from '../account/cancel-account-modal/cancel-account-modal.component';
import {ExtendTrialModalComponent} from '../extend-trial-modal/extend-trial-modal.component';
import {filter, Observable} from 'rxjs';
import {SendSmsModalComponent} from '../account/send-sms-modal/send-sms-modal.component';
import {ConfirmationModalComponent} from '../../shared/modal/confirmation-modal/confirmation-modal.component';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../../shared/error/error.service';
import {CallRecordingsComponent} from './call-recordings/call-recordings.component';
import {ReactivateAccountModalComponent} from '../account/reactivate-account-modal/reactivate-account-modal.component';
import {PageTitleService} from '../../shared/page/page-title.service';
import {AddressHistoryComponent} from '../address-history/address-history.component';
import {DeferredAuthModalComponent} from '../../card-services/components/deferred-auth-modal/deferred-auth-modal.component';
import {AlertService} from "@ratespecial/core";
import {SendEmailModalComponent} from '../account/send-email-modal/send-email-modal.component';
import {OneTimePasswordModalComponent} from 'src/app/customer/one-time-password-modal/one-time-password-modal.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {User} from 'src/app/shared/models/user.model';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import {CustomerState} from 'src/app/shared/state/customer.state';
import * as CustomerActions from 'src/app/shared/state/customer.actions';
import {StatusIndicatorComponent} from './status-indicator/status-indicator.component';
import {AsyncPipe, CurrencyPipe, NgFor, NgIf} from '@angular/common';
import {AddNoteButtonComponent} from './action-buttons/add-note-button/add-note-button.component';
import {AgencyGuidComponent} from './agency-guid/agency-guid.component';
import {DineIncentiveComponent} from './dine-incentive/dine-incentive.component';
import {SmartOffersIncentiveComponent} from './smart-offers-incentive/smart-offers-incentive.component';
import {ThreedsInfoComponent} from './threeds-info/threeds-info.component';
import {AfidComponent} from './afid/afid.component';
import {DarkWebInfoComponent} from './dark-web-info/dark-web-info.component';
import {CustomerSmsAlertsComponent} from './sms-alerts/customer-sms-alerts.component';
import {CustomerAlertsComponent} from './customer-alerts/customer-alerts.component';
import {CustomerTransactionsComponent} from './customer-transactions/customer-transactions.component';
import {CustomerNotesComponent} from '../../notes/customer-notes/customer-notes.component';
import {CustomerLoginsComponent} from './customer-logins/customer-logins.component';
import {MailEventsComponent} from './mail-events/mail-events.component';
import {SmsEventsComponent} from './sms-events/sms-events.component';
import {CustomerCancelEventsComponent} from './customer-cancel-events/customer-cancel-events.component';
import {CustomerOfferClicksComponent} from './customer-offer-clicks/customer-offer-clicks.component';
import {ReportHistoryComponent} from './report-history/report-history.component';
import {ClickLogComponent} from './click-log/click-log.component';
import {ReportPostLogComponent} from './report-post-log/report-post-log.component';
import {CapitalizePipe} from '../../shared/pipes/capitalize.pipe';
import {UkPhonePipe} from '../../shared/pipes/uk-phone.pipe';
import {DateTimeFormatPipe} from '../../shared/pipes/date-time-format.pipe';
import {StatusFieldComponent} from 'src/app/customer/customer-detail/status-field/status-field.component';
import {UserAbilityDirective} from 'src/app/shared/auth/user-ability.directive';


@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
  standalone: true,
  imports: [
    StatusIndicatorComponent,
    NgIf,
    RouterLink,
    NgbTooltip,
    AddNoteButtonComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    AgencyGuidComponent,
    DineIncentiveComponent,
    SmartOffersIncentiveComponent,
    ThreedsInfoComponent,
    AfidComponent,
    DarkWebInfoComponent,
    NgFor,
    CustomerSmsAlertsComponent,
    CustomerAlertsComponent,
    CustomerTransactionsComponent,
    CustomerNotesComponent,
    CustomerLoginsComponent,
    MailEventsComponent,
    SmsEventsComponent,
    CustomerCancelEventsComponent,
    CustomerOfferClicksComponent,
    ReportHistoryComponent,
    ClickLogComponent,
    ReportPostLogComponent,
    AsyncPipe,
    CurrencyPipe,
    UserAbilityDirective,
    UkPhonePipe,
    DateTimeFormatPipe,
    StatusFieldComponent,
  ],
})
export class CustomerDetailComponent {

  public customer: Customer;
  public user: User;

  public rent = this.store.selectSignal(CustomerState.getRentInfo);

  /**
   * State flag to prevent navigation away from detail page without adding a note
   */
  public hasAddedOrBypassedNote: boolean = false;
  public addNoteBlockedUrl = '';

  // Add reference to Object.keys function to template
  public objectKeys = Object.keys;

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private modalService: NgbModal,
    private alertService: AlertService,
    public userService: UserService,
    private errorService: ErrorService,
    pageTitleService: PageTitleService,
    private store: Store,
  ) {
    this.store.select(SessionState.getUser)
      .pipe(takeUntilDestroyed())
      .subscribe(user => this.user = user);

    // Subscribe to store.  It will be populated by the resolver.
    this.store.select(CustomerState.getCustomer)
      .pipe(filter(cust => cust != null))
      .subscribe(customer => {
        this.customer = customer;
        this.addNoteBlockedUrl = '';
        this.hasAddedOrBypassedNote = false;

        if (customer.fullname) {
          pageTitleService.setTitle(customer.fullname);
        }
      });

    // Subscribe to updates that effect displayed customer data
    this.initSubscriptions();
  }

  /**
   * Watch for events so we can update display
   */
  private initSubscriptions(): void {
    // Subscribe to route changes to remember where user wanted to go after addNoteModal fires
    this.router.events.pipe(takeUntilDestroyed()).subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.addNoteBlockedUrl = val.url;
      }
    });
  }


  /**
   * The "AddNoteGuard" prevents navigation away from detail view calling this function to pop the AddNoteModal
   * In order to do this, we have to record where the user was trying to go, so we can go there after the modal
   * is closed.
   */
  canDeactivate(): Observable<boolean> | boolean {
    this.hasAddedOrBypassedNote = true;

    return this.hasAddedOrBypassedNote;
  }


  /**
   * Show Edit Customer Modal
   */
  editCustomer() {
    const modalRef = this.modalService.open(CustomerEditModalComponent);

    modalRef.componentInstance.loadFormModel(this.customer);
  }

  /**
   * Show Change AFID Modal
   */
  changeAFID() {
    const modalRef = this.modalService.open(ChangeAfidModalComponent);

    modalRef.componentInstance.loadCustomer(this.customer);
  }

  addedNote(): void {
    this.hasAddedOrBypassedNote = true;
  }

  /**
   * Show Downsell (Pricepoint Update) Modal
   */
  downsell() {
    const modalRef = this.modalService.open(DownsellModalComponent);

    modalRef.componentInstance.form.guid = this.customer.guid;
    modalRef.componentInstance.recurring_price = this.customer.recurring_price;
  }

  extendTrial() {
    const modalRef = this.modalService.open(ExtendTrialModalComponent);

    modalRef.componentInstance.customer = this.customer.getCustomerLite();
    modalRef.componentInstance.customer.trial_end_date = this.customer.trial_end_date;
  }

  /**
   * Show Reset Password Modal
   */
  resetPassword() {
    const modalRef = this.modalService.open(PasswordResetModalComponent, { size: 'lg' });

    modalRef.componentInstance.customer = this.customer;
  }

  /**
   * Show Cancel Account Modal
   */
  cancelAccount() {
    const modalRef = this.modalService.open(CancelAccountModalComponent);

    modalRef.componentInstance.customer = this.customer.getCustomerLite();
  }

  /**
   * Show Reactivate Account Modal
   */
  reactivateAccount() {
    const modalRef = this.modalService.open(ReactivateAccountModalComponent);

    modalRef.componentInstance.customer = this.customer.getCustomerLite();
  }

  /**
   * Show Send SMS Modal
   */
  sendSms() {
    const modalRef = this.modalService.open(SendSmsModalComponent);

    modalRef.componentInstance.customer = this.customer.getCustomerLite();
  }

  sendEmail() {
    const modalRef = this.modalService.open(SendEmailModalComponent);

    modalRef.componentInstance.customer = this.customer.getCustomerLite();
  }

  /**
   * Open Call Recording Modal
   */
  openCallRecordingModal() {
    const modalRef = this.modalService.open(CallRecordingsComponent);

    modalRef.componentInstance.customer = this.customer.getCustomerLite();
  }

  /**
   * Show Delete Customer Confirmation Modal
   */
  async deletePii() {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = 'Delete Customer';
    modalRef.componentInstance.message = 'Are you sure you want to clear all PII data for this customer?';

    const shouldDelete = await modalRef.result;
    if (shouldDelete) {
      this.deleteCustomer();
    }
  }

  private deleteCustomer() {
    this.customerService
      .delete(this.customer.guid)
      .subscribe({
        next:
          () => {
            this.alertService.success('Customer deleted.');
            this.customer.status = 'deleted';
          },
        error: (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        },
      });
  }

  isNotCancelled(): boolean {
    // Customer cannot already be in a cancelled or chargeback state
    return ['cancelled', 'chargeback'].indexOf(this.customer.status) === -1;
  }

  openAddressHistory(): void {
    const modalRef = this.modalService.open(AddressHistoryComponent);

    modalRef.componentInstance.addressHistory = this.customer.address_history;
  }

  async openCreditCardModal() {
    const modalRef = this.modalService.open(DeferredAuthModalComponent, {size: 'lg'});

    if (modalRef) {
      modalRef.componentInstance.guid = this.customer.guid;
      modalRef.componentInstance.customer = this.customer.getCustomerLite();
      modalRef.componentInstance.modalInit();

      const isSuccess = await modalRef.result;
      if (isSuccess) {
        this.store.dispatch(new CustomerActions.UpdateCustomer('', true)).subscribe();
      }
    }
  }

  getDeferredAuthLabel(): string {
    let label = 'No';

    if (this.customer.deferred_auth === 1) {
      label = 'Yes, ';

      if (this.customer.deferred_auth_complete === 1) {
        label += 'Complete';
      } else {
        label += 'Incomplete';
      }
    }

    return label;
  }

  refreshReport(): void {
    if (confirm('This will pull a new copy of the customers report.')) {
      this.customerService.refreshReport(this.customer.guid).subscribe({
        next: () => {
          this.alertService.success('Report refreshed');
        },
        error: (e: HttpErrorResponse) => {
          this.alertService.error(e.error.message);
        },
      });
    }
  }

  sendOnetimePassword(): void {
    const modal = this.modalService.open(OneTimePasswordModalComponent);
    modal.componentInstance.customer = this.customer;
  }


}
