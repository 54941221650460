import {Component} from '@angular/core';
import {CustomerThreedsInterface} from "../models/customer-threeds.interface";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import { NgFor } from '@angular/common';
import { DateTimeFormatPipe } from '../../shared/pipes/date-time-format.pipe';

@Component({
    selector: 'app-threeds-info-modal',
    templateUrl: './threeds-info-modal.component.html',
    styleUrls: ['./threeds-info-modal.component.scss'],
    standalone: true,
    imports: [NgFor, DateTimeFormatPipe]
})
export class ThreedsInfoModalComponent {

  threeds: CustomerThreedsInterface;

  // Add reference to Object.keys function to template
  objectKeys = Object.keys;

  constructor(public activeModal: NgbActiveModal) { }

}
