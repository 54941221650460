import {Observable, of as observableOf} from 'rxjs';

import {map, tap} from 'rxjs/operators';
/**
 * Reason Service
 *
 * Fetches and caches copy of reasons table
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {Reason} from '../../models/reason.model';

@Injectable({providedIn: 'root'})
export class RefundReasonService {

  private resourceUrl = '/api/account/reasons';
  public reasons: Array<Reason> = [];

  constructor(private http: HttpClient) {
  }

  fetch(): Observable<Array<Reason>> {
    return this.http
      .post(this.resourceUrl, {group: 'refund'}).pipe(
      map(
        resp => resp as Array<Reason>
      ),
      tap(
        resp => {
          this.reasons = resp as Array<Reason>;
        }
      ), );
  }

  get(force?: boolean): Observable<Array<Reason>> {

    if (this.reasons.length === 0 || force === true) {
      return this.fetch();
    }

    return observableOf(this.reasons).pipe(map(
      resp => resp as Array<Reason>
    ));
  }
}
