import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReportHistoryService} from '../../services/report-history.service';
import {ReportProviderFetchLogInterface} from '../../models/report-provider-fetch-log.interface';
import { NgClass, NgIf, NgFor, SlicePipe } from '@angular/common';
import { NgbTooltip, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeFormatPipe } from '../../../shared/pipes/date-time-format.pipe';


@Component({
    selector: 'app-report-history',
    templateUrl: './report-history.component.html',
    styleUrls: ['./report-history.component.css'],
    standalone: true,
    imports: [NgClass, NgIf, NgFor, NgbTooltip, NgbPagination, SlicePipe, DateTimeFormatPipe]
})
export class ReportHistoryComponent implements OnInit {

  public reportHistory: Array<ReportProviderFetchLogInterface>;

  public pagination: any = {
    start: 0,
    end: 10,
    current_page: 1
  };


  constructor(
    private route: ActivatedRoute,
    private reportHistoryService: ReportHistoryService
  ) {
  }

  ngOnInit() {
    // Watch for GUID changes
    this.route.params.subscribe(
      params => {
        this.fetchReportHistory(params['guid']);
      }
    );
  }

  onPageChange(page): void {
    this.pagination.current_page = page;
    this.pagination.start = page * 10 - 10;
    this.pagination.end = page * 10;
  }

  private fetchReportHistory(guid): void {
    this.reportHistoryService.get(guid).subscribe(reportHistory => {
      this.reportHistory = reportHistory;
    });
  }
}
