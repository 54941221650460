import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {CustomerLite} from '../../models/customer-lite.model';
import {ErrorService} from '../../../shared/error/error.service';
import {SendSmsService} from '../services/send-sms.service';
import {AlertService} from "@ratespecial/core";
import { ErrorComponent } from '../../../shared/error/error.component';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-send-sms-modal',
    templateUrl: './send-sms-modal.component.html',
    styleUrls: ['./send-sms-modal.component.css'],
    standalone: true,
    imports: [ErrorComponent, FormsModule, NgClass]
})
export class SendSmsModalComponent implements OnInit {

  /**
   * Customer Data needed for request
   */
  public customer: CustomerLite;

  /**
   * Reason select options
   */
  public reasons: Array<any> = [];

  public sendSmsRequest = {
      guid: null,
      phone: null,
      message: null
  };

  // Add reference to Object.keys function to template
  public objectKeys = Object.keys;


  constructor(
      private sendSmsService: SendSmsService,
      private route: ActivatedRoute,
      private modalService: NgbModal,
      public activeModal: NgbActiveModal,
      private alertService: AlertService,
      public errorService: ErrorService
  ) {}


  ngOnInit() {
    this.errorService.clearErrors();


  }

  sendSms() {
    this.errorService.clearErrors();
    this.sendSmsRequest.guid = this.customer.guid;
      this.sendSmsRequest.phone = this.customer.phone;

    this.sendSmsService.sendSms(this.sendSmsRequest)
        .subscribe(
            (data) => {
              this.alertService.success('SMS sent to customer!');
              this.close();
            },
            (err: HttpErrorResponse) => {
              this.errorService.handleValidationErrors(err);
            }
        );
  }

  close() {
    this.activeModal.close();
  }
}
