import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerService} from '../../services/customer.service';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-transunion-modal',
    templateUrl: 'transunion-modal.component.html',
    styleUrls: ['transunion-modal.component.css'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class TransunionModalComponent {

  public customer: any;
  public accountStatus?: boolean;
  public accountClosed?: boolean;
  public additionalActions: string[] = [];
  public verificationError?: string;

  constructor(
    private customerService: CustomerService,
    public activeModal: NgbActiveModal,
  ) {
  }

  queryAccount() {
    this.additionalActions = [];
    this.verificationError = undefined;

    this.customerService
      .queryAccountStatus(this.customer?.guid)
      .subscribe({
        next: (result: boolean) => {
          this.accountStatus = result;
          if (result && this.customer?.status === 'manual') {
            this.updateManualVerifyCustomer();
          }
        },
        error: () => {
          this.verificationError = 'Failed to verify TransUnion account status';
          this.accountStatus = undefined;
        }
      });
  }

  private updateManualVerifyCustomer() {
    this.customerService
      .updateManualVerifyStatus(this.customer.guid)
      .subscribe({
        next: (response: any) => {
          this.additionalActions.push('Customer status updated to active');
          this.additionalActions.push('Report cache cleared');
        },
        error: () => {
          this.verificationError = 'Failed to complete manual verification';
        }
      });
  }


  closeUser() {
    if (confirm('Are you sure you want to close the TransUnion account?')) {
      this.customerService
        .closeUser(this.customer?.guid)
        .subscribe(status => {
          this.accountClosed = status;
        });

    }
  }

  close() {
    this.activeModal.close();
  }
}
