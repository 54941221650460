import {tap} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {ProspectInterface} from '../models/prospect.interface';


@Injectable({providedIn: 'root'})
export class ProspectService {

    private resourceUrl = 'api/prospect';
    private searchUrl = 'api/prospects/search';

    constructor(private http: HttpClient) {
    }

    /**
     * Notification to any subscribers of note save events
     * @type {EventEmitter<boolean>}
     */
    @Output() onProspectUpdateEvent = new EventEmitter<any>();


    get(guid) {
        return this.http.get<ProspectInterface>(this.resourceUrl + '/' + guid);
    }


    search(searchCriteria, page) {
        let params = new HttpParams();
        params = params.append('page', page);

        Object.keys(searchCriteria).map(key => {
            if (searchCriteria[key]) {
                params = params.append(key, searchCriteria[key]);
            }
        });

        return this.http.get<LengthAwarePaginator>(this.searchUrl, {params: params});
    }


    save(params, guid = null) {
        if (params.id > 0) {
            return this.update(params);
        } else {
            return this.create(params);
        }
    }


    create(params) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.post(this.resourceUrl, params, {headers}).pipe(
            tap(prospect => {
                this.notify(prospect);
            }));
    }


    update(params) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.put(this.resourceUrl + '/' + params.id, params, {headers}).pipe(
            tap(prospect => {
                this.notify(prospect);
            }));
    }


    markContacted(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.post(this.resourceUrl + '/contact/' + id, {}, {headers}).pipe(
            tap(prospect => {
                this.notify(prospect);
            }));
    }


    notify(event) {
        this.onProspectUpdateEvent.emit(event);
    }

    delete(prospectId) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.delete(this.resourceUrl + '/' + prospectId, {headers}).pipe(
            tap(prospect => {
                this.notify(prospect);
            }));
    }
}
