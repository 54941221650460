import {Component} from '@angular/core';
import {DarkWebInfoInterface} from '../models/dark-web-info.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { NgFor } from '@angular/common';
import { DateTimeFormatPipe } from '../../shared/pipes/date-time-format.pipe';

@Component({
    selector: 'app-dark-web-info-modal',
    templateUrl: './dark-web-info-modal.component.html',
    styleUrls: ['./dark-web-info-modal.component.scss'],
    standalone: true,
    imports: [NgFor, DateTimeFormatPipe]
})
export class DarkWebInfoModalComponent {

  public darkWebInfo: DarkWebInfoInterface = null;

  constructor(public activeModal: NgbActiveModal) { }

}
