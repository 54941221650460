import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from "@ratespecial/core";
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-offer-email-modal',
    templateUrl: './offer-email-modal.component.html',
    styleUrls: ['./offer-email-modal.component.css'],
    standalone: true,
    imports: [FormsModule, NgClass]
})
export class OfferEmailModalComponent implements OnInit {

    private resourceUrl = 'api/prospect/emailoffer';

    public emailOfferRequest = {
        id: 0,
        guid: '',
        email_id: 3,
    };

    public errors = [];

    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        public activeModal: NgbActiveModal) {}

    ngOnInit() {
    }


    save() {

        return this.http.post(this.resourceUrl, this.emailOfferRequest).subscribe(
            (data) => {
                this.alertService.success('Prospect Email Sent');
                this.close();
            },
            (err: HttpErrorResponse) => {
                if (err.error instanceof Error) {
                    this.alertService.error('A Network Error Occurred');
                } else {
                    this.errors = JSON.parse(err.error);
                }
            });
    }


    close() {
        this.activeModal.close();
    }
}
