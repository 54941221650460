import {Component} from '@angular/core';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {DineSubscriptionInterface} from '../models/dine-subscription.interface';
import { NgFor } from '@angular/common';
import { CapitalizePipe } from '../../shared/pipes/capitalize.pipe';
import { DateTimeFormatPipe } from '../../shared/pipes/date-time-format.pipe';

@Component({
    selector: 'app-dine-card-info-modal',
    templateUrl: './dine-card-info-modal.component.html',
    styleUrls: ['./dine-card-info-modal.component.scss'],
    standalone: true,
    imports: [NgbTooltip, NgFor, CapitalizePipe, DateTimeFormatPipe]
})
export class DineCardInfoModalComponent {

  public subscription: DineSubscriptionInterface = null;

  constructor(public activeModal: NgbActiveModal) { }

}
