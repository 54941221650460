<div class="modal-header">
  <h5 class="modal-title">Update Customer Pricepoint</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <form (ngSubmit)="save()" #formRef="ngForm">
    <div class="form-group">
      <!-- Free option, only shown with permission -->
      @if (canMakeAccountFree) {
        <div class="form-check mb-3">
          <input class="form-check-input"
                 type="radio"
                 name="pricepointRadio"
                 id="pricepoint-free"
                 [value]="19"
                 [(ngModel)]="form.bsid"
                 (change)="onSelectionChange({bsid: 19, recurring_price: '0.00'})">
          <label class="form-check-label" for="pricepoint-free">
            £0.00
          </label>
        </div>

        <!-- Separator -->
        <div class="separator mb-3">
          <hr>
        </div>
      }

      <!-- Regular paid options -->
      @for (pricepoint of pricepoints; track pricepoint.bsid) {
        @if (pricepoint.recurring_price !== '0.00') {
          <div class="form-check">
            <input class="form-check-input"
                   type="radio"
                   name="pricepointRadio"
                   [id]="'pricepoint-' + pricepoint.bsid"
                   [value]="pricepoint.bsid"
                   [(ngModel)]="form.bsid"
                   (change)="onSelectionChange(pricepoint)">
            <label class="form-check-label" [for]="'pricepoint-' + pricepoint.bsid">
              £{{pricepoint.recurring_price}}
            </label>
          </div>
        }
      }
      @empty {
        <div class="alert alert-info">No pricing options available</div>
      }
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()" [disabled]="!form.bsid">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
</div>
