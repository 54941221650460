import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Params, Router, RouterLink} from '@angular/router';
import {CustomerService} from '../services/customer.service';
import {NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {PageTitleService} from '../../shared/page/page-title.service';
import {AlertService} from "@ratespecial/core";
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CustomerSearchResponse} from 'src/app/customer/models/customer-search-response';


@Component({
  selector: 'app-customer-search',
  templateUrl: 'customer-search.component.html',
  styleUrls: ['customer-search.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbTooltip,
    NgbInputDatepicker,
    RouterLink,
  ],
})
export class CustomerSearchComponent implements OnInit {

  public memberstatusOptions = ['active', 'manual', 'prospect', 'cancelled', 'chargeback', 'returnreverse', 'thin_file'];

  /**
   * Search Results
   */
  public customers: CustomerSearchResponse[] = [];


  public products = [
    {
      id: '',
      name: 'Any',
    },
    {
      id: 1,
      name: 'UKCreditRatings',
    },
    {
      id: 2,
      name: 'MyUKCredit',
    },
    {
      id: 3,
      name: 'StudentCreditChecker',
    },
  ];


  /**
   * Form Model
   */
  public searchModel = {
    fname: '',
    lname: '',
    email: '',
    post_code: '',
    last_four: '',
    date_start: '',
    date_end: '',
    transaction_date_start: '',
    transaction_date_end: '',
    member_status: '',
    phone: '',
    product_id: '',
    afid: '',
    cid: '',
    deferredAuth: '', // meta field for both deferred_auth and deferred_auth_complete in a customer
  };

  /**
   * Date Structures For Date Picker
   */
  public dateStart: NgbDateStruct = null;
  public dateEnd: NgbDateStruct = null;
  public transactionDateStart: NgbDateStruct = null;
  public transactionDateEnd: NgbDateStruct = null;


  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private customerService: CustomerService,
              private ngbDateParserFormatter: NgbDateParserFormatter,
              private alertService: AlertService,
              private pageTitleService: PageTitleService) {
  }


  /**
   * On page load, check for querystring search params and do the search
   */
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // Autosearch from URL params
      if (Object.keys(params).length > 0) {
        this.searchModel.afid = params['afid'] ? params['afid'] : '';
        this.searchModel.cid = params['cid'] ? params['cid'] : '';
        this.searchModel.fname = params['fname'] ? params['fname'] : '';
        this.searchModel.lname = params['lname'] ? params['lname'] : '';
        this.searchModel.email = params['email'] ? params['email'] : '';
        this.searchModel.post_code = params['post_code'] ? params['post_code'] : '';
        this.searchModel.last_four = params['last_four'] ? params['last_four'] : '';
        this.searchModel.date_start = params['date_start'] ? params['date_start'] : '';
        this.searchModel.date_end = params['date_end'] ? params['date_end'] : '';
        this.searchModel.transaction_date_start = params['transaction_date_start'] ? params['transaction_date_start'] : '';
        this.searchModel.transaction_date_end = params['transaction_date_end'] ? params['transaction_date_end'] : '';
        this.searchModel.member_status = params['member_status'] ? params['member_status'] : '';
        this.searchModel.phone = params['phone'] ? params['phone'] : '';
        this.searchModel.deferredAuth = params['deferredAuth'] ? params['deferredAuth'] : '';
        this.dateStart = this.ngbDateParserFormatter.parse(this.searchModel.date_start);
        this.dateEnd = this.ngbDateParserFormatter.parse(this.searchModel.date_end);
        this.transactionDateStart = this.ngbDateParserFormatter.parse(this.searchModel.transaction_date_start);
        this.transactionDateEnd = this.ngbDateParserFormatter.parse(this.searchModel.transaction_date_end);
        this.search();
      }
    });

    this.pageTitleService.setTitle('Customer Search');
  }


  /**
   * Handles form submit by placing search params on url and then navigating to the autosearch route
   */
  submit() {
    this.searchModel.date_start = this.ngbDateParserFormatter.format(this.dateStart);
    this.searchModel.date_end = this.ngbDateParserFormatter.format(this.dateEnd);
    this.searchModel.transaction_date_start = this.ngbDateParserFormatter.format(this.transactionDateStart);
    this.searchModel.transaction_date_end = this.ngbDateParserFormatter.format(this.transactionDateEnd);

    // Add params and navigate to autosearch url
    this.router.navigate(['/customer/search'], {queryParams: this.searchModel});
  }

  /**
   * Perform the search
   */
  private search(): void {
    // Convert Date Structs to ISO8601 String and add to form model
    // this.searchModel.date_start = this.ngbDateParserFormatter.format(this.dateStart);
    // this.searchModel.date_end = this.ngbDateParserFormatter.format(this.dateEnd);

    this.customerService.search(this.searchModel).subscribe({
      next: data => {
        this.customers = data['customers'];
        if (this.customers.length < 1) {
          this.alertService.warning('No results found for the given search parameters.');
        }
      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      },
    });
  }


  /**
   * Handle search errors
   * @param {HttpErrorResponse} err
   */
  private handleError(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
      this.alertService.error('A Network Error Occurred');
    } else {
      // Check if form validation errors
      if (err.status === 422) {
        this.alertService.error('At least one search parameter must be entered.  The Product field, if used, must be included with one other field.  If deferred auth is used, specify Membership Status.');
      } else {
        err.error.errors.map(error => {
          this.alertService.addApiError(error);
        });
      }
    }
  }


  /**
   * Reset the form
   */
  reset() {
    this.dateStart = null;
    this.dateEnd = null;
    this.transactionDateStart = null;
    this.transactionDateEnd = null;
    Object
      .keys(this.searchModel)
      .map((key) => {
        this.searchModel[key] = '';
      });
    this.router.navigate(['/customer/search']);
    this.customers = [];
  }

}
