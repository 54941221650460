import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ChargebackService {

  constructor(private http: HttpClient) {
  }

  refund(chargebackRequest) {
    return this.http.post('api/transaction/log/chargeback', chargebackRequest);
  }

}
