import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {Customer} from 'src/app/customer/models/customer.model';
import {CapitalizePipe} from 'src/app/shared/pipes/capitalize.pipe';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-status-field',
  standalone: true,
  imports: [
    CapitalizePipe,
    NgbTooltip,
  ],
  templateUrl: './status-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusFieldComponent {
  customer = input.required<Customer>();
}
