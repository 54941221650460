import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { DatePipe, NgIf, NgFor } from '@angular/common';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerService} from '../services/customer.service';
import {CustomerLiteInterface} from '../models/customer-lite.interface';
import {APP_CONFIG} from '../../app.config';
import {IAppConfig} from '../../app-config.interface';
import {ExtendTrialResponse} from '../models/extend-trial-response';
import {AlertService} from "@ratespecial/core";
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-extend-trial-modal',
    templateUrl: './extend-trial-modal.component.html',
    styleUrls: ['./extend-trial-modal.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, FormsModule, DatePipe]
})
export class ExtendTrialModalComponent implements OnInit {

  public customer: CustomerLiteInterface;
  public errors = [];
  public newTrialEndDate: Date;

  // Add reference to Object.keys function to template
  public objectKeys = Object.keys;

  constructor(
    @Inject(APP_CONFIG) public config: IAppConfig,
    private http: HttpClient,
    private alertService: AlertService,
    private customerService: CustomerService,
    public activeModal: NgbActiveModal
  ) {
  }


  ngOnInit() {
    this.setTrialDate();
  }

  /**
   * Compute future date for trial end
   */
  setTrialDate() {
    // JS dates are converted to the local browser's timezone automatically from UTC.  If we
    // give the date object just a date, it will be treated as midnight in UTC, and therefore
    // convert to GMT-8 for us in the States, showing us the wrong date.  So I gave it 12 noon
    // UTC, which will still be in the same day when we view it here.
    this.newTrialEndDate = new Date(this.customer.trial_end_date + 'T12:00:00Z');

    this.newTrialEndDate.setDate(this.newTrialEndDate.getDate() + 30);
  }

  /**
   * Make extend trial request
   */
  extendTrial() {
    this.customerService
      .extendTrial(this.customer.guid)
      .subscribe({
        next: (data: ExtendTrialResponse) => {
          const dp = new DatePipe('en-UK');
          this.alertService.success('Customer Trial Extended to ' + dp.transform(data.new_trial_date, this.config.dateFormat));
          this.close();
        },
        error: (err: HttpErrorResponse) => {
          this.handleValidationErrors(err);
        }
      });
  }

  /**
   * Close Modal
   */
  close() {
    this.activeModal.close();
  }


  /**
   * Handle 422 Form Validation Errors
   * @param err
   */
  handleValidationErrors(err) {
    if (err.status === 422) {
      err.error.errors.map(error => {
        this.alertService.addApiError(error);
      });
    }

    this.close();
  }
}
