import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MailEvent} from '../models/mail-event.model';

@Injectable({providedIn: 'root'})
export class MailEventService {

    private resourceUrl = 'api/logs/mailevents';

    constructor(private http: HttpClient) {
    }

    get(guid) {
        return this.http.get<Array<MailEvent>>(this.resourceUrl + '/' + guid);
    }
}
